import { Assets, Botch, BotchType, Image, Separator } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './ParentalLeave.scss'

const VoucherImage = Assets.folder('illustrations').imageSet(Texts.ParentalLeave.Image.name, Texts.ParentalLeave.Image.alt)
const ParentalLeaveBotches = [BotchType.OrangePink, BotchType.YellowPurple]

export const ParentalLeave = () => {
  const parseText = (text: string) => {
    return <span dangerouslySetInnerHTML={{ __html: text }}></span>
  }

  const renderBotch = (index: number) => {
    return <Botch className={`c-parental-leave__botch c-parental-leave__botch--${ParentalLeaveBotches[index]}`} botchType={ParentalLeaveBotches[index]}></Botch>
  }

  return (
    <section className="c-parental-leave">
      <div className='c-parental-leave__wrapper'>
        <div className='c-parental-leave__illustration-container'>
          <Image className='c-parental-leave__image' {...VoucherImage}></Image>
        </div>
        <div className='c-parental-leave__content'>
         <div className='c-parental-leave__ticker'>
                <h3 className='c-parental-leave__title'>{Texts.ParentalLeave.Ticker[0].Title}</h3>
                <div className='c-parental-leave__text'>
                  {renderBotch(0)}
                  {Texts.ParentalLeave.Ticker[0].Text.map((item, index) => {
                    return <p key={index} className={`c-parental-leave__paragraph--${item.highlight ? 'highlight' : 'normal'}`}>{parseText(item.text)}</p>
                  })}
                </div>
              </div>
        </div>
      </div>
      <div className='c-parental-leave__content c-parental-leave__content--update'>
        <div className='c-parental-leave__ticker'>
            <h3 className='c-parental-leave__title'>{Texts.ParentalLeave.Ticker[1].Title}</h3>

          <div className='c-parental-leave__date-container'>
            <div className='c-parental-leave__date'>11. Februar 2025</div>
            <div className='c-parental-leave__text'>
                    {Texts.ParentalLeave.Ticker[1].Text.map((item, index) => {
                      return <p key={index} className={`c-parental-leave__paragraph--${item.highlight ? 'highlight' : 'normal'}`}>{parseText(item.text)}</p>
                    })}
            </div>
          </div>
        </div>
      </div>
      <Separator sizeAppearance={'small'}></Separator>
    </section>
  )
}
